import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ImageCarousel from '../components/ImageCarousel'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

const shortcodes = { ImageCarousel }

class PageTemplate extends React.Component<GatsbyProps> {
    render() {
        const post = this.props.data.mdx
        return (
            <Layout location={this.props.location}>
                <article className="container flex-grow mx-auto p-4">
                    <header>
                        <h1>{post.frontmatter.title}</h1>
                    </header>
                    <section>
                        <MDXProvider components={shortcodes}>
                            <MDXRenderer>{post.body}</MDXRenderer>
                        </MDXProvider>
                    </section>
                </article>
            </Layout>
        )
    }
}

export default PageTemplate

export const pageQuery = graphql`
    query ProductBySlug($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            body
            frontmatter {
                title
                # date(formatString: "MMMM DD, YYYY")
                # description
            }
        }
    }
`
