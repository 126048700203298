import React from 'react'
import EmblaCarousel from './EmblaCarousel'

const ImageCarouselComponent = ({ children }) => {
    const c = children.props.children.filter(
        c => c.type && c.type.displayName === 'MDXCreateElement'
    )
    return <EmblaCarousel>{c}</EmblaCarousel>
}

export default ImageCarouselComponent
